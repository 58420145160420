import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { Typography, Box, Grid, Button } from "@mui/material";
import styled from "@emotion/styled";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import Container from "../components/Container";
import NewLayout from "../components/NewLayout";
import LandingLayout from "../components/landings/LandingLayout";

const landingPaths = ["/solutions/", "/segments/", "/products/"];

const isLandingPath = (pathname) => {
  return landingPaths.some((path) => pathname.includes(path));
};

function NotFoundPage() {
  const [backUrl, setBackUrl] = useState("/");
  const [isOnLandingPage, setIsOnLandingPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const currentPathname = location?.pathname || "";
    setIsOnLandingPage(isLandingPath(currentPathname));

    const isTargetBlank = window.opener || window.history.length === 1;
    const referrer = document.referrer;
    const previousUrl = sessionStorage.getItem("previousUrl");

    const calculatedBackUrl = isTargetBlank
      ? "/"
      : referrer?.includes(window.location.origin)
        ? -1
        : previousUrl?.includes(window.location.origin)
          ? -1
          : "/";

    setBackUrl(calculatedBackUrl);
  }, []);

  const Layout = isOnLandingPage ? LandingLayout : NewLayout;

  const handleBackClick = () => navigate(backUrl);

  return (
    <Layout {...(!isOnLandingPage && {
              byDefault: true,
            })}>
      <Container component="section" p={{ xs: 4, md: 0 }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          height={isOnLandingPage && "calc(100dvh - 105px)"}
        >
          <Grid item xs={12} md={4} display={{ xs: "none", md: "block" }}>
            <Box>
              <img
                src="https://app.allfunds.com/docs/cms/no_found_faa4111cc3.gif"
                alt="404"
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: 650,
                  objectFit: "contain",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} my={4}>
            <CustomNumber>
              <Typography>
                <Trans>404</Trans>
              </Typography>
            </CustomNumber>
            <Typography
              variant="h1"
              align="center"
              mb={2}
              lineHeight={1}
              sx={{ fontSize: { xs: 40, md: 60 } }}
            >
              <Trans>Page Not Found</Trans>
            </Typography>
            <Typography variant="body1" align="center">
              <Trans>
                We couldn't find the page you were looking for. It might have
                been moved, <br /> deleted, or maybe the URL is a little off.
                But don't worry, we've got your back!
              </Trans>
            </Typography>
            <Box mt={2} textAlign="center">
              <Button variant="secondary-dark" onClick={handleBackClick}>
                <Typography
                  variant="button"
                  fontWeight={600}
                  fontSize={16}
                  p={1}
                >
                  {backUrl === "/" ? (
                    <Trans>Go Home</Trans>
                  ) : (
                    <Trans>Back</Trans>
                  )}
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default NotFoundPage;

const CustomNumber = styled(Box)(
  ({ theme }) => `
  > p {
    color: #447CCB;
    text-align: center;
    font-size: 231.642px;
    font-weight: 600;
    line-height: 1;
  }

  ${theme.breakpoints.down("500")} {
    > p {
      font-size: 150px;
    }
  }
`,
);
